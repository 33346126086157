import React from "react"

const steps = ["Account Setup", /*"Select Subcription", "Select Carriers",*/ "Invite Team"]

function getStepClass(stepIndex, activeIndex) {
  if (stepIndex === activeIndex) {
    return " active"
  } else if (stepIndex < activeIndex) {
    return " done"
  }
  return ""
}

const SignUpProgress = ({ activeStep }) => (
  <section className="container">
    <div className="row sign-up-progress mx-auto no-gutters">
      {steps.map((step, index) => (
        <div className="col-sm progress-step" key={"step_" + index}>
          <div className={"text-center position-relative" + getStepClass(index, activeStep)}>
            <div className="progress-square mx-sm-auto mr-auto mb-2">{index + 1}</div>
            <small className="progress-text">{step}</small>
          </div>
        </div>
      ))}
    </div>
  </section>
)
export default SignUpProgress
