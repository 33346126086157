import React, { Component } from "react"
import LayoutComponent from "../components/layout.component"
import HeaderComponent from "../components/header.component"
import SignUpProgress from "../components/sign-up-progress"
import { navigate } from "gatsby"

const axios = require("axios")

class SelectCarriersComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountId: null,
      loginToken: null,
      carriers: [],
      pageIndex: 0,
      pageSize: 20,
      pagesCount: 0,
      carrierName: "",
      modes: "",
      states: "",
      errors: null,
      selectedCarriers: [],
      currentCarriers: [],
    }

    this.continueClicked = this.continueClicked.bind(this)
    this.selectCarrier = this.selectCarrier.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.setPage = this.setPage.bind(this)
    this.nextPage = this.nextPage.bind(this)
    this.prevPage = this.prevPage.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  selectCarrier(id) {
    const i = this.state.selectedCarriers.indexOf(id)
    if (i < 0) {
      this.state.selectedCarriers.push(id)
      this.setState({
        selectedCarriers: this.state.selectedCarriers,
      })
    } else {
      this.state.selectedCarriers.splice(i, 1)
      this.setState({
        selectedCarriers: this.state.selectedCarriers,
      })
    }
  }

  setPage(value) {
    this.setState(
      {
        pageIndex: value,
      },
      () => this.displayCarriers()
    )
  }

  componentDidMount() {
    const sstat = sessionStorage.getItem("wdx_signup")
    if (sstat) {
      const ostat = JSON.parse(sstat)
      if (!ostat.accountId) {
        navigate("sign-up")
        return
      }

      this.setState({
        accountId: ostat.accountId,
        loginToken: ostat.loginToken,
      })
    } else {
      navigate("sign-up")
      return
    }

    this.fillCarriers()
  }

  fillCarriers() {
    this.setError(null)

    const serverUrl = process.env.API_URL + "carrier"
    axios
      .get(serverUrl)
      .then(response => {
        const pages = Math.ceil(
          +response.data.totalRecords / this.state.pageSize
        )

        this.setState(
          {
            carriers: response.data.carriers,
            pagesCount: pages,
          },
          () => this.displayCarriers()
        )
      })
      .catch(error => {
        this.setError(error)
      })
  }

  nextPage(e) {
    e.preventDefault()

    if (this.state.pageIndex < this.state.pagesCount - 1) {
      this.setState(
        {
          pageIndex: this.state.pageIndex + 1,
        },
        () => this.displayCarriers()
      )
    }
  }

  prevPage(e) {
    e.preventDefault()

    if (this.state.pageIndex > 0) {
      this.setState(
        {
          pageIndex: this.state.pageIndex - 1,
        },
        () => this.displayCarriers()
      )
    }
  }

  displayCarriers() {
    const start = this.state.pageIndex * this.state.pageSize
    const carriers = this.state.carriers.slice(
      start,
      start + this.state.pageSize
    )

    this.setState({
      currentCarriers: carriers,
    })
  }

  continueClicked(e) {
    this.setError(null)

    let serverUrl = process.env.API_URL + "carrier"
    axios
      .post(serverUrl, {
        userId: 0,
        accountId: this.state.accountId,
        carriers: this.state.selectedCarriers,
      })
      .then(response => {
        this.goToInviteTeams()
      })
      .catch(error => {
        this.setError(error)
      })
  }

  setError(error) {
    let err = null
    if (error) {
      if (error && error.response && error.response.data) {
        err = error.response.data
      } else {
        err = "There is an error occured"
      }
    }

    this.setState({
      error: err,
    })
  }

  goToInviteTeams() {
    navigate("invite-teams", {
      state: {
        accountId: this.state.accountId,
        loginToken: this.state.loginToken,
      },
    })
  }

  render() {
    const logoPath =
      process.env.MAIN_SITE_URL + "CompanyLogo.aspx?height=16&width=50&cid="

    const isCarrierSelected = id => this.state.selectedCarriers.indexOf(id) >= 0

    const getServices = c => {
      let arr = []
      if (c.hasLTLService) {
        arr.push("LTL")
      }
      if (c.hasDrayageService) {
        arr.push("Drayage")
      }
      if (c.hasLoadService) {
        arr.push("TL")
      }
      if (c.hasSkidService) {
        arr.push("Skid")
      }

      for (let i = 1; i < arr.length; i += 2) {
        arr.splice(i, 0, ", ")
      }

      return arr.join("")
    }

    const carrierItems = this.state.currentCarriers.map(c => (
      <tr key={c.id}>
        <td>
          <div className="select-carrier-block d-flex">
            <div className="select-carrier-img">
              {/*<LocalImage alt="img" src={logoPath + c.id}/>*/}
              <img alt="logo" src={logoPath + c.id} />
            </div>
            <div className="ml-3">
              <b>{c.name}</b>
              <br />
              <span className="font-12">
                <a href={"http://" + c.url}>{c.url}</a>
              </span>
            </div>
          </div>
        </td>
        <td>{getServices(c)}</td>
        {/*<td></td>*/}
        <td className="carrier-btn-col">
          {(this.state.selectedCarriers.length >= 10 &&
            !isCarrierSelected(c.accountId)) || (
            <button
              type="button"
              className={
                "btn btn-block btn-sm " +
                (isCarrierSelected(c.accountId) ? "btn-success" : "btn-info")
              }
              onClick={() => this.selectCarrier(c.accountId)}
            >
              {isCarrierSelected(c.accountId) ? "Selected" : "Select"}
            </button>
          )}
        </td>
      </tr>
    ))

    let pageIndexStart = this.state.pageIndex - 3
    if (this.state.pagesCount < 7) {
      pageIndexStart = 0
    } else {
      if (pageIndexStart < 0) pageIndexStart = 0
      else if (pageIndexStart + 7 >= this.state.pagesCount)
        pageIndexStart = this.state.pagesCount - 7
    }

    const pages = [...Array(Math.min(7, this.state.pagesCount)).keys()].map(
      (val, i) => (
        <li
          className={
            "page-item " +
            (this.state.pageIndex === i + pageIndexStart ? "active" : "")
          }
          key={"page_" + i}
        >
          <a
            className="page-link"
            href="/#"
            onClick={e => {
              e.preventDefault()
              this.setPage(i + pageIndexStart)
            }}
          >
            {i + pageIndexStart + 1}
          </a>
        </li>
      )
    )

    return (
      <LayoutComponent>
        {/*FIXME:*/}
        {/*<SeoComponent title="Select Carriers"/>*/}
        <div className="container mb-100">
          <HeaderComponent theme="light" showMenu="false" />
          <div className="text-center my-5">
            <h2 className="text-primary mb-3">Select Carriers</h2>
          </div>
          <SignUpProgress activeStep={1} key="signupProgress" />
          <div className="text-center text-select-carriers">
            <h4 className="text-dark-blue mb-4">
              Please select up to 10 carriers for your account
            </h4>
            {this.state.error && (
              <div className="my-2 text-red">{this.state.error}</div>
            )}
            <button
              type="button"
              className={
                "btn btn-sm btn-primary mb-4 " +
                (this.state.selectedCarriers.length ? "" : "disabled")
              }
              onClick={this.continueClicked}
            >
              Continue
            </button>
          </div>
          <section className="mb-5 overflow-auto">
            <table className="table">
              <thead>
                <tr className="font-weight-bold text-secondary font-14 pl-4">
                  <th scope="col">CARRIER NAME</th>
                  <th scope="col">MODES</th>
                  {/*<th scope="col">STATES</th>*/}
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {/*<tr>
                <td>
                  <input type="search"
                         className="form-control"
                         placeholder="Search"
                         name="carrierName"
                         value={this.state.carrierName}
                         onChange={this.handleInputChange} />
                </td>
                <td><input type="text"
                           className="form-control"
                           name="modes"
                           value={this.state.modes}
                           onChange={this.handleInputChange} />
                </td>
                <td><input type="text"
                           className="form-control"
                           name="states"
                           value={this.state.states}
                           onChange={this.handleInputChange}/>
                </td>
                <td>&nbsp;</td>
              </tr>*/}
                {carrierItems}
              </tbody>
            </table>
          </section>
          <section className="mb-100">
            <nav aria-label="Page navigation" className="text-center">
              <ul className="pagination d-inline-flex">
                <li className="page-item">
                  <a
                    className="page-link"
                    href="/#"
                    aria-label="Previous"
                    onClick={e => this.prevPage(e)}
                  >
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </li>
                {pages}
                {/*
                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                <li className="page-item"><a className="page-link" href="#">2</a></li>
                <li className="page-item"><a className="page-link" href="#">3</a></li>
                <li className="page-item"><a className="page-link" href="#">4</a></li>*/}
                <li className="page-item">
                  <a
                    className="page-link"
                    href="/#"
                    aria-label="Next"
                    onClick={e => this.nextPage(e)}
                  >
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Next</span>
                  </a>
                </li>
              </ul>
              <div className="text-center">
                <small className="">
                  Page {this.state.pageIndex + 1} of {this.state.pagesCount}
                </small>
              </div>
            </nav>
          </section>
        </div>
      </LayoutComponent>
    )
  }
}

export default SelectCarriersComponent
